import { HistoryIcon, Button, useModal, TransactionsIcon } from '@pancakeswap/uikit'
import TransactionsModal from './TransactionsModal'

const Transactions = ({ width = 30, height = 30, stroke = '' }) => {
  const [onPresentTransactionsModal] = useModal(<TransactionsModal />)
  return (
    <>
      <Button height={height} variant="text" p={0} onClick={onPresentTransactionsModal} mr="13px">
        <TransactionsIcon style={{ fill: 'none' }} stroke={stroke} width={width} height={height} />
      </Button>
    </>
  )
}

export default Transactions
