import { Text, Flex, Heading, IconButton, ArrowBackIcon, NotificationDot } from '@pancakeswap/uikit'
import GlobalSettings from 'components/Menu/GlobalSettings'
import Link from 'next/link'

import { useExpertModeManager } from 'state/user/hooks'
import styled, { useTheme } from 'styled-components'

import QuestionHelper from '../QuestionHelper'

import Transactions from './Transactions'

interface Props {
  title: string
  titleFontSize?: string | number
  subtitle?: string
  subtitleFontSize?: string | number
  helper?: string
  backTo?: string
  noConfig?: boolean
}

const AppHeaderContainer = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  padding: 18px 24px 20px;
  width: 100%;
  color: ${({ theme }) => theme.colors.hardTextColor};
`

const StyledSubText = styled(Text)`
  color: ${({ theme }) => theme.colors.softTextColor};
`

const AppHeader: React.FC<Props> = ({
  title,
  titleFontSize,
  subtitle,
  subtitleFontSize = 12,
  helper,
  backTo,
  noConfig = false,
}) => {
  const [expertMode] = useExpertModeManager()
  const theme = useTheme()

  return (
    <AppHeaderContainer>
      <Flex alignItems="center" mr={noConfig ? 0 : '16px'}>
        {backTo && (
          <Link passHref href={backTo}>
            <IconButton as="a" style={{ width: '44px', color: theme.colors.softTextColor }}>
              <ArrowBackIcon style={{ fill: 'none' }} color={theme.colors.softTextColor} width="24px" />
            </IconButton>
          </Link>
        )}
        <Flex flexDirection="column">
          <Heading as="h2" fontSize={titleFontSize}>
            {title}
          </Heading>
          {subtitle && (
            <Flex alignItems="center">
              {helper && <QuestionHelper text={helper} mr="4px" placement="top-start" />}
              <StyledSubText fontSize={subtitleFontSize} fontWeight={400}>
                {subtitle}
              </StyledSubText>
            </Flex>
          )}
        </Flex>
      </Flex>
      {!noConfig && (
        <Flex alignItems="center">
          <Transactions width={20} height={20} stroke={theme.colors.softTextColor} />
          <NotificationDot show={expertMode}>
            <GlobalSettings width={22} height={22} fill={theme.colors.softTextColor} />
          </NotificationDot>
        </Flex>
      )}
    </AppHeaderContainer>
  )
}

export default AppHeader
