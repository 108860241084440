import styled from 'styled-components'
import { Card } from '@pancakeswap/uikit'

export const BodyWrapper = styled(Card)`
  background: ${({ theme }) => `${theme.colors.cardBackground}`};
  backdrop-filter: blur(12.5px);
  width: 100%;
  ${({ theme }) => theme.mediaQueries.sm} {
    max-width: 380px;
  }
  z-index: 1;
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children, className }: { children: React.ReactNode; className?: string }) {
  return <BodyWrapper className={className}>{children}</BodyWrapper>
}
